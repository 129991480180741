import React from "react";

// External

// Internal
import Layout from "../components/layout";
import Seo from "../components/SEO";
import ReviewsSection from "../containers/reviews-section";

const TestimonialsPage = () => {
  return (
    <Layout>
      <Seo title="Testimonials" description="Testimonials from our clients" />
      <ReviewsSection />
    </Layout>
  );
};

export default TestimonialsPage;
